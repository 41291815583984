/**
 * 获取验证码
 */
import { ref } from "vue"
import { fly, getSmsCodeUl } from '../../../requestUrl'
import showTitle from '../../../utils/showTitle'
export default function (phoneRef) {
    const waitForItemRef = ref(60);
    const btnTextRef = ref('获取验证码');
    const timerOutRef = ref(null);
    const disableBtnRef = ref(false);
    const getCode = function () {
        fly.get(`${getSmsCodeUl}?phone=${phoneRef.value}`).then(res => {
            if (res.data.code != 0) {
                showTitle('手机号格式错误');
                return
            }
            btnTextRef.value = `${waitForItemRef.value}s重新获取`;
            disableBtnRef.value = true;
            countDown();
        });
    }
    const countDown = function () {
        clearInterval(timerOutRef.value);
        timerOutRef.value = setInterval(() => {
            waitForItemRef.value -= 1;
            btnTextRef.value = `${waitForItemRef.value}s重新获取`;
            if (waitForItemRef.value <= 0) {
                btnTextRef.value = `获取验证码`;
                disableBtnRef.value = false;
                waitForItemRef.value = 60;
                clearInterval(timerOutRef.value);
            }
        }, 1000)
    }
    return {
        getCode,
        waitForItemRef,
        btnTextRef,
        disableBtnRef
    }
}