<template>
  <div class="attract-container">
    <div class="attract-top container-width">
      <img
        src="https://cosmos-static.oss-cn-shanghai.aliyuncs.com/ztofficialwebsite/pc/yuanquzhaoshang.png"
      />
    </div>
    <div class="attract-con container-width">
      <div class="zhong-title con-title">园区产业扶持政策</div>
      <div>
        <div>
          <div class="title-text">支持双创建设</div>
          <p>
            1、对获得国家、省、市知识产权管理部门项目资金支持的企业和服务机构，按照实际获得资金额50%的比例给予配套资金支持最高不超过50万元。对获批国家知识产权优势示苑企业，一次性奖励30万元。
          </p>
          <p>
            2、对新认定的国家、国际技术转移机构，最高给予100万元补助；新认定的省、市级技术转移机构，分别一次性给予30万元、10万元补助。
          </p>
          <p>
            3、对新引进落户本区的高新技术企业按照企业规模给予最高100万元奖励；对新认定的省级科技创新龙头企业、科技瞪羚企业、科枝小巨人企业，一次性分别奖励50万元、30万元、10万元。
          </p>
          <p>
            4、对本区企业获国家、省、市级重大科技专项项目，按上级支持经费10%的比例给予配套经费补助，最高不超过200万元。
          </p>
        </div>
      </div>
      <div>
        <div>
          <div class="title-text">企业开办奖励</div>
          <p>
            1、对新引进中小微数字企业，三年内按该企业年度区级经济贡献60%给予扶持，最高不超过100万元。
          </p>
          <p>
            2、对年度主营业务收入首次突破1000万元、2000万元、5000万元、1亿元的优秀大数据企业，按照市级财政补贴的10%分别给予配套奖补，最高不超过300万元。
          </p>
          <p>
            3、开设亚洲首店、中国（内地）首店、河南首店、旗舰店的国际品牌企业，开设综合商超类首店租金或建安成本超过1000万元的，将按照项目核定实际投资总额的50%给予支持，最高补助500万元。
          </p>
          <p>
            4、对新设立或新迁入，重点从事高端商贸、文化创意、节能环保、生物医药、人工智能、网络安全、智能装备、5G等新兴产业链的企业总部，一次性按照上年度区级经济贡献的30%予以奖励。
          </p>
          <p>
            5、在沪深交易所主板、科创板、创业板及在境外成功上市的企业，给予400万元奖励。
          </p>
        </div>
      </div>
      <div>
        <div>
          <div class="title-text">经营贡献奖励</div>
          <p>
            1、引入园区高新技术企业、专精特新企业、科技业、规模以上企业或重大科研机构、新型研发平台，在辖区产业园购置厂房面积不低于500㎡且全部用于自用的，按照1500元/㎡给予企业一次性购房数补贴，最高补贴金额不超过1000万元。
          </p>
          <p>
            2、在园区内开展生产经营且税收贡献达到
            1200元/㎡/年以上的入驻企业，将给予“三年免租”的租金扶持政策。
          </p>
        </div>
      </div>

      <div>
        <div>
          <div class="title-text">人才引进培育</div>
          <p>
            1、对于优势产业、新兴产业和现代服务业领域的重点企业中，具有较高技术水平、从事企业核心业务的急需紧缺人才，依据能力素质、紧缺指数、薪酬水平等指标以积分评价方式择优认定，2年内给予人才5万一10万元奖励。
          </p>
          <p>
            2、对于活跃在创新创业一线且具有成长为高端人才潜力的地方级领军人才（C
            类）和突出贡献人才（D类）,分别给予50万元、20万元奖励。
          </p>
          <p>
            3、对A、B类高层次人才，给予最高300万元、150
            万元的首次购房补贴或提供不超过200平米、150平米的免租住房。对Ｃ、Ｄ类高层次人才，给予最高100万元、50万元首次购房补贴。
          </p>
        </div>
      </div>
      <div>
        <div>
          <div class="title-text">支持电商发展</div>
          <p>
            1、对年进出口额在线交易100万美元以上的企业，给予在线交易额1%的资金扶持，每家企业资金扶持不超过30万元人民币。
          </p>
          <p>
            2、对年在线交易额达到5000万元人民币以上，服务企业达到100家以上的电子商务交易平台，给予50万元人民币的资金扶持。
          </p>
          <p>
            3、奖励金额按照电商企业实际租货面积每平方米100元给予扶持，每个园区扶持资金最高不超过200万元人民币，分三年依次按照30%、30%、40%比例兑付。
          </p>
          <p>
            4、对辖区企业或机构为电子商务应用企业提供第三方电子商务平台服务、年在线交易额达到3000万元人民币以上，入驻商户60家以上的电子商务交易平台，给予30万元人民币的资金扶持。
          </p>
        </div>
      </div>
      <div>
        <div>
          <div class="title-text">投、融资扶持</div>
          <p>
            1、设立小微企业园产业投资基金，基金总规模1.5亿元，定向优先对入园企业提供投融资需求。
          </p>
          <p>
            2、设立园区天使投资种子基金，基金总规模1500万元，丰富园区投融资平台体系，
            解决信用资产良好的小微企业融资难题。
          </p>
          <p>
            3、对园区内企业投资的各类投资基金，按投资总额5%的比例、最高不超过100万给予基金管理机构奖励。
          </p>
        </div>
      </div>
    </div>
    <div class="attract-hand">
      <div class="zhong-title">部分入驻企业展示</div>
      <div class="zhong-container">
        <div>
          <img src="../../assets/images/AttractInvestment/erqi.png" />
        </div>
        <div>
          <img src="../../assets/images/AttractInvestment/linghang.png" />
        </div>
        <div>
          <img src="../../assets/images/AttractInvestment/wandou.png" />
        </div>
        <div>
          <img src="../../assets/images/AttractInvestment/tefei.png" />
        </div>
        <div>
          <img src="../../assets/images/AttractInvestment/huiye.png" />
        </div>
        <div>
          <img src="../../assets/images/AttractInvestment/kajing.png" />
        </div>
      </div>
    </div>
    <div class="attract-bottom container-width">
      <div class="attract-bottom-titile">
        如果您有入园意向，请留下您的信息，我们将在第一时间联系您。
      </div>
      <div class="attract-input ">
        <div>
          <label>企业名称</label>
          <input placeholder="请输入" class="input" v-model="companyRef" />
        </div>
        <div>
          <label>姓名</label>
          <input placeholder="请输入" class="input" v-model="nameRef" />
        </div>
        <div>
          <label>手机</label>
          <div class="phoneI">
            <input placeholder="请输入" class="input" v-model="phoneRef" style="" maxlength="11" />
            <div class="phoneB">
              <button
                class="btn"
                style="
                  width: 120px;
                  height: 34px;
                  line-height: 34px;
                  font-size: 18px;
                "
                @click="getCode"
                :disabled="disableBtnRef"
              >
                {{ btnTextRef }}
              </button>
            </div>
          </div>
        </div>
        <div class="yanzheng">
          <label class="bian">验证码</label>
          <input
            placeholder="请输入"
            class="input"
            style="margin-right: 0px; width: 100px"
            v-model="codeRef"
          />
        </div>
      </div>
      <div style="text-align: center; margin-top: 40px">
        <button class="btn" style="width: 200px" @click="sumberBtn">
          提交
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import getVerification from "./competition/getVerification";
import submitInformation from "./competition/submitInformation";
export default {
  setup() {
    const infoRef = submitInformation();
    const { phoneRef } = infoRef;
    return {
      ...infoRef,
      ...getVerification(phoneRef),
    };
  },
};
</script>

<style scoped>
.attract-top img {
  width: 100%;

}
.attract-con p {
  font-size: 18px;
  color: #555;
  margin-bottom: 25px;
}
.title-text {
  margin-bottom: 10px;
  margin-left: 2px;
}
.container-width p {
  line-height: 26px;
}

.attract-hand {
  /* width: 1920px; */
  width: 100vw !important;
  background: rgba(242, 245, 248, 1);
  opacity: 1;
  overflow: hidden;
}
.phoneI {
  position: relative;
  display: inline-block;
}
.phoneB {
  position: absolute;
  right: 54px;
  top: 24px;
}
.zhong-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  max-width: 1420px;
  margin: 0 auto;
}
.zhong-container div {
  width: 470px;
}
.zhong-container div img {
  width: 100%;
  height: 100%;
}
.zhong-title {
  font-size: 24px;
  font-family: PingFang SC;
  font-weight: 800;
  color: #000000;
  opacity: 1;
  position: relative;
  text-align: center;
  padding: 40px 0 10px 0;
}
.zhong-title::after {
  position: absolute;
  content: "";
  display: block;
  width: 60px;
  height: 6px;
  background: rgba(0, 0, 0, 1);
  opacity: 1;
  left: calc(50% - 30px);
  bottom: 0px;
}
.attract-bottom-titile {
  font-size: 24px;
  font-weight: bold;
  color: #000000;
  text-align: center;
}
.attract-bottom {
  background: rgba(255, 255, 255, 1);
  padding: 40px 0;
}
label {
  color: #000000;
  font-size: 20px;
  margin-right: 20px;
  display: inline-block;
  /*width: 80px;*/
  text-align: right;
}
.attract-input {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  /* margin-top: 40px; */
  max-width: 1480px;
  margin:  20px auto 40px;
  padding: 0 0;
}
.input {
  margin-right: 50px;
  width: 260px;
  margin-top: 20px;
}
@media screen and (max-width:1520px) {
  .title-text{
    margin-left: 50px;
  }
  .attract-bottom{
    padding: 40px;
  }
  .bian{
    margin-right: 40px;
  }
}
@media screen and (max-width:1400px) {
  .yanzheng{
    margin-right: 210px;
  }
}
</style>
