/**
 * 提交用户信息
 */
import showTitle from '../../../utils/showTitle'
import {ref} from 'vue'
import {fly, sitesSubmitUrl} from '../../../requestUrl'

export default function () {
    //公司名称
    const companyRef = ref("");
    //姓名
    const nameRef = ref("");
    //手机号
    const phoneRef = ref("");
    //验证
    const codeRef = ref("");
    const sumberBtn = () => {
        if (!companyRef.value) {
            showTitle("请输入企业名称", '提示');
            return
        }
        if (!nameRef.value) {
            showTitle("请输入姓名", '提示');
            return
        }
        if (!phoneRef.value) {
            showTitle("请输入手机号", '提示');
            return
        }
        if (!codeRef.value) {
            showTitle("请输入验证码", '提示');
            return
        }
        fly.post(sitesSubmitUrl, {
            regionCode:localStorage.getItem('regionCode'),
            "company": companyRef.value, //必填
            "name": nameRef.value,//必填
            "phone": phoneRef.value,//必填
            "code": codeRef.value
        }).then(res => {
            if (res.data.code != 0 && res.data.code != -797) {
                showTitle(res.data.msg, '提示');
                return
            } else if (res.data.code == -797) {
                const msg = `<p style="text-align: center">该企业已提交</p>
                            <p style="margin-top: 10px;text-align: center">您可主动拨打招商电话咨询</p>
                            <p style="margin-top: 10px;text-align: center">0371-68187777</p>`;
                showTitle(msg, '提示');
                return
            } else {
                showTitle('我们将会在第一时间联系您，请耐心等待。', '提交成功');
            }
        })

    }

    return {
        companyRef,
        nameRef,
        phoneRef,
        sumberBtn,
        codeRef
    }
}
