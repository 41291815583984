import { ElMessage, ElMessageBox } from 'element-plus'
export default function (title,titHand = '提示') {
    ElMessageBox.confirm(
        title,
        titHand,
        {
            confirmButtonText: 'OK',
            cancelButtonText: '关闭',
            type: 'warning',
            center: true,
            dangerouslyUseHTMLString: true,
        }
    )
        .then(() => {

        })
        .catch(() => {

        })
}
